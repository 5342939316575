/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import { sessionStorage } from "../../../../../assets/myjs/glovar.js";
export default {
  name: "ywInfo",
  data() {
    return {
      ywInfoData: {}
      //   ywInfoData: {
      //     fullname: "了对接肯定凯撒奖到里面撒开聊大幅扫卡了",
      //   },
    };
  },

  created() {
    var ywInfoData = sessionStorage.getItem("ywdata");
    this.ywInfoData = JSON.parse(ywInfoData);
  },
  methods: {
    onClickLeft() {
      this.$router.push("/ywProject");
      sessionStorage.removeItem("ywdata");
    }
  }
};